.header {
  --header-background: rgba(94, 108, 108, 0.4);
  //--header-background: #5e6c6c ;
  --header-shadwo: #444444;
  --header-text-color: var(--white-500);
  --header-bg-color: #3b424280;
  --menu-icon-background: var(--metal-300);
  --menu-icon-fill-color: #eee;

  --search-form-icon-color: var(--white-700);

  @media (max-width: 991px) {
    --search-form-icon-color: var(--red-500);
  }

  .light & {
    --header-background: rgba(255, 255, 255, 0.4);
    --header-shadwo: #000000;
    --header-text-color: #000;

    --menu-icon-background: var(--white-300);
    --menu-icon-fill-color: #000;
    --header-bg-color: transparnt;
    --search-form-icon-color: var(--red-500);
    box-shadow: none;
    background-color: #fff;

  }

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  //box-shadow: 0 0 15px -4px var(--header-shadow);

  &__menu-open {
    z-index: 1050;
  }

  .header-bg {
    background-color: var(--header-bg-color);
  }

  .header-logo {
    width: fit-content;
    flex-grow: 0;

    h1 {
      color: var(--menu-icon-fill-color);
    }

    svg {
      fill: var(--menu-icon-fill-color);
    }
  }

  @media (max-width: 567px) {
    .container {
      max-width: 100% !important;
      margin: 0;
      padding: 0;
    }
  }
  @media (min-width: 568px) {
    .container {
      max-width: 95% !important;
      margin: 0 auto;
      padding: 0;
    }
  }
  @media (max-width: 991px) {
    .user-controls-container {
      display: none;
    }
  }
  @media (max-width: 1251px) {
    .search-bar-container {
      display: none;
    }
  }


  .header-container {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    h1 {
      margin: 0;
      line-height: 1;
      width: 110px;
      color: var(--header-text-color);

      svg {
        width: 120px;
        height: 24px;
        color: var(--header-text-color);
      }
    }

    .mobile-menu {
      width: fit-content;
      font-size: 14px;

      svg {
        width: 23px;
        height: 23px;
        fill: var(--menu-icon-fill-color);
      }

      .header-search-icon {
        fill: var(--search-form-icon-color);
      }

      li {
        & + li {
          margin-left: 15px;

          [dir="rtl"] & {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
    }

    .mobile-menu {
      flex-grow: 0;

      @media (max-width: 1250px) {
        display: flex;
        justify-content: center;
      }
      @media (min-width: 1251px) {
        display: none;
      }

      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        line-height: 36px;
        align-items: center;
      }
    }

    .login {
      text-decoration: underline;
    }

    .register {
      display: block;
      padding: 0 22px;
      height: 36px;
      border: 1px solid;
      line-height: 34px;
      border-radius: 5px;
      text-decoration: none !important;
      white-space: nowrap;
    }
  }
}

.profile-image-dark {
  display: block;

  .light & {
    display: none;
  }
}

.profile-image-light {
  display: none;

  .light & {
    display: block;
  }
}
