.align-start {
  [dir="rtl"] & {
    text-align: right;
  }

  [dir="ltr"] & {
    text-align: left;
  }
}

.align-end {
  [dir="rtl"] & {
    text-align: left;
  }

  [dir="ltr"] & {
    text-align: right;
  }
}

.page-title {
  margin-bottom: 40px;
  font-size: 24px;

  @media (max-width: 991px) {
    font-size: 22px;
  }
}

.disable-selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
