.navigation-tabs-container {
  --tab-lable-active-text-color: #eeeeee;
  --tab-lable-text-color: #a1a1a1;
  --tab-link-bar-color: #eb2027;

  .light & {
    --tab-lable-active-text-color: #000000;
    --tab-lable-text-color: #0a1726;
  }

  width: 100%;
  .mdc-tab {
    margin-top: 28px;
    color: var(--tab-lable-text-color);
    opacity: 1;
    font-size: 20px;
    padding: 0;
    min-width: 70px;
    font-weight: normal;
    letter-spacing: normal;
    &.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
      color: var(--tab-lable-active-text-color);
      font-weight: 700;
    }

    [dir='ltr'] & {
      margin-right: 15px;
      margin-left: 0;
    }

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 15px;
    }

    @media (max-width: 375px) {
      font-size: 12px;
      [dir='ltr'] & {
        margin-right: 5px;
        margin-left: 0;
      }

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: 5px;
      }
    }
    @media (min-width: 376px) and (max-width: 520px) {
      font-size: 14px;
      [dir='ltr'] & {
        margin-right: 10px;
        margin-left: 0;
      }

      [dir='rtl'] & {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  .mdc-tab-indicator {
    left: 5px;
    right: 0px;
    width: calc(100% - 17px);
    .mdc-tab-indicator__content--underline {
      height: 5px;
      background-color: var(--tab-link-bar-color);
    }
  }
  .mat-mdc-tab-labels {
    width: 100%;
    margin: 0 auto;
  }

  .mat-mdc-tab-body {
    margin: 40px 0;
  }

  .mat-mdc-tab-header {
    width: 50%;
    border: none;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .mat-tab-header-pagination {
    width: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
  }
}
