.btn {
  padding-left: 28px;
  padding-right: 28px;
  &-sm {
    padding-left: 20px;
    padding-right: 20px;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;

    &:first-child {
      [dir="ltr"] & {
        margin-right: 10px;
      }

      [dir="rtl"] & {
        margin-left: 10px;
      }
    }

    &.arrow {
      width: 20px;

      [dir="rtl"] & {
        margin-right: 10px;
        transform: scaleX(-1);
      }

      [dir="ltr"] & {
        margin-left: 10px;
      }
    }
  }

  &-danger {
    background-color: var(--red-500);
  }

  &-icon {
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: none !important;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &-outline-light {
    .light & {
      color: #000;
      border-color: #000;
    }
  }

  &-outline-tangerine {
    color: var(--red-200);
    border-color: var(--red-200);

    &:hover {
      background-color: var(--red-200);
      color: #000;
    }

    .light & {
      color: var(--red-200);
      border-color: var(--red-200);

      &:hover {
        background-color: var(--red-200);
        color: #fff;
      }
    }
  }

  &-link {
    padding: 0;
    color: #0a8db1;
    margin: 0 7px;
    outline: none;

    &:focus, &:hover {
      outline: none;
      box-shadow: none;
      color: #0a8db1;
    }
  }

  &-social {
    color: #fff;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    svg {
      fill: currentColor;
      width: 45px;
      height: 45px;
      position: relative;
      margin: 0 !important;

      [dir="rtl"] & {
        margin-left: 5px !important;
      }

      [dir="ltr"] & {
        margin-right: 5px !important;
      }
    }

    &:hover {
      color: #fff;
    }
  }

  &-facebook {
    background: var(--blue-facebook);
  }

  &-google {
    background: #4285f4;
    color: var(--white-400);
    //svg{
    //  color: #000000;
    //}
    &:hover {
      //background: var(--red-500);
      //color: #fff;
      //background: var(--white);
      //color: var(--white-700);
      //svg{
      //  color: currentColor;
      //}
      //.light &{
      //  box-shadow: none!important;
      //}
    }
    //.light &{
    //  box-shadow: 0 0 5px 0 rgb(0 0 0 / 15%);
    //
    //}
  }

  &-checkbox {
    border-radius: 19px;
    background: var(--metal-400);
    color: var(--white-500);
    padding: 6px 14px;

    &:hover {
      color: var(--white-500);
    }

    &.active {
      background: var(--red-200);
      color: #000;
    }

    .light & {
      background: var(--white-300);
      color: #000;

      &.active {
        background: var(--red-500);
        color: #fff;
      }
    }
  }

  &-alm {
    &-filter {
      border-radius: 20px;
    }
  }
  &-alm-filled {
    background-color: #eb2027;
    border-radius: 5px;
    color: #fff;
    &:hover {
      background-color: #eee;
      color: #eb2027;
      border-color: #eee;
    }
    .light & {
      &:hover {
        background-color: #fff;
        color: #eb2027;
        border-color: #eb2027;
      }
    }
  }
  &-alm-outline {
    background-color: transparent;
    border-color: #eeeeef;
    color: #eee;
    border-radius: 5px;
    &:hover {
      background-color: #eee;
      border-color: #eee;
      color: #eb2027;
    }
    .light & {
      border-color: #eb2027;
      color: #eb2027;
      background-color: transparent;

      &:hover {
        color: #eb2027;
        border-color: #eee;
        background-color: #eee;
      }
    }
  }
  &-alm-preview {
      background-color: transparent;
      border-color: #eeeeef;
      color: #eee;
      border-radius: 5px;
    &:hover {
      color: #eb2027;
      border-color: #eee;
      background-color: #eee;
    }
      .light & {
        border-color: #eee;
        color: #eee;
        background-color: transparent;

        &:hover {
          color: #eb2027;
          border-color: #eee;
          background-color: #eee;
        }
      }
  }

  &-alm-close {
    border: solid 1px #252a2a;
    background-color: rgba(0, 0, 0, 0.38);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      box-shadow: none !important;
      outline: none;
    }

    svg {
      fill: #a1a1a1;
      width: 20px;
      height: 20px;
      @media(max-width: 991px) {
        width: 15px;
        height: 15px;
      }
    }

    .light & {
      border: solid 1px #d4d4d4;
      background-color: #f3f1f1;

      svg {
        fill: #5d6666;
      }
    }
  }
}

button.button--loading > * {
  visibility: hidden;
}

button.button--loading, button.button--loading:hover {
  position: relative;
  cursor: default;
  background-color: #aaaaaa!important;
  border-color: #aaaaaa!important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
  &, .mat-icon {
    color: transparent!important;
  }
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #181818;
  border-top: 2px solid transparent;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s spin linear infinite;

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
