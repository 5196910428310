body {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	text-align: unset;
}

.title {
	font-size: 24px;
	line-height: 1.17;
	font-weight: bold;

	@media (min-width: 768px) {
		font-size: 30px;
		line-height: 1.3;
	}
}

.text {
	font-size: 14px;
	line-height: 1.43;
}
