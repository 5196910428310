.swiper-button-next, .swiper-button-prev {
  --navigation-button-background: #0d0d0d;
  --navigation-button-shadow: rgba(255, 255, 255, 0.2);

  .light & {
    --navigation-button-background: #ffffff;
    --navigation-button-shadow: rgba(0, 0, 0, 0.2);
    //box-shadow: 0 0 10px 0 var(--navigation-button-shadow);
  }

  @media (max-width: 768px) {
    display: none;
  }
  width: 44px !important;
  height: 44px !important;
  border-radius: 50%;
  background-color: var(--navigation-button-background);
  opacity: 0.90;

  .light & {
    opacity: 1;
  }

  &::after {
    content: '' !important;
    width: 38px !important;
    height: 38px !important;
    border-radius: 50%;
    background: url("/assets/images/arrow-down-dark.svg") no-repeat center;
    background-size: 25px;
  }

  &.swiper-button-disabled {
    display: none !important;
  }
}

.swiper-button-next {
  [dir='ltr'] & {
    right: 10px;
    left: auto;
    transform: rotate(270deg);
    @media screen and (max-width: 768px) {
      right: 0 !important;
    }
  }

  [dir='rtl'] & {
    left: 10px;
    right: auto;
    transform: rotate(90deg);
    @media screen and (max-width: 768px) {
      left: 0 !important;
    }
  }
}

.swiper-button-prev {
  [dir='ltr'] & {
    transform: rotate(90deg);
    left: 5px;
    right: auto;
    @media screen and (max-width: 768px) {
      left: 0 !important;
    }
  }

  [dir='rtl'] & {
    transform: rotate(270deg);
    right: 5px;
    left: auto;
    @media screen and (max-width: 768px) {
      right: 0 !important;
    }
  }
}

[dir='ltr'] {
  .swiper-prev-pos {
    @media (min-width: 1236px) {
      left: -52px;
    }

    @media (min-width: 1200px) and (max-width: 1235px) {
      left: -43px;
    }

    @media (min-width: 1054px) and (max-width: 1199px) {
      left: -52px;
    }

    @media (min-width: 1024px) and (max-width: 1053px) {
      left: -43px;
    }

    @media (max-width: 1023px) {
      left: -32px;
    }
  }
}

[dir='rtl'] {
  .swiper-prev-pos {
    @media (min-width: 1236px) {
      right: -52px;
    }

    @media (min-width: 1200px) and (max-width: 1235px) {
      right: -40px;
    }

    @media (min-width: 1054px) and (max-width: 1199px) {
      right: -52px;
    }

    @media (min-width: 1024px) and (max-width: 1053px) {
      right: -40px;
    }

    @media (max-width: 1023px) {
      right: -32px;
    }
  }
}
