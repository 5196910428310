/* You can add global styles to this file, and also import other style files */

// Swiper
@import "swiper/swiper-bundle.css";


// Quill
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.snow.css";

// TOASTER
@import "ngx-toastr/toastr";

// SHARE
//@import "ngx-sharebuttons/themes/default";

// VIDEOJS
// @import "@almentor/alm-video-player-lib/src/video-js.min.css";

// Settings
@import "./assets/styles/settings/variables";

// Generics
@import "./assets/styles/generic/typography";

// ELements
@import "./assets/styles/elements/body";
@import "./assets/styles/elements/anchor";
@import "./assets/styles/elements/scrollbar";

@import "./assets/styles/components/button";
@import "./assets/styles/components/section";
@import "./assets/styles/components/modals";
@import "./assets/styles/components/forms";
@import "./assets/styles/components/swipers";
@import "./assets/styles/components/navigation-tab";
@import "./assets/styles/components/header";
@import "./assets/styles/components/cards";
@import "./assets/styles/components/mini-cart";
@import "./assets/styles/components/toaster";
@import "./assets/styles/components/embla-carousel";
@import "./assets/styles/components/payment-forms";

// Utilities
@import "./assets/styles/utilities/text-utilities";
@import "./assets/styles/utilities/links-style";
@import "./assets/styles/utilities/material";

@font-face {
  font-family: 'JF-Flat';
  src: url('assets/fonts/JF-Flat-Regular.woff');
  unicode-range: U+0600-06FF, U+0750-077F, U+08A0-08FF, U+FB50-FDFF, U+FE70-FEFF, U+10E60-10E7F, U+1EE00-1EEFF;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  position: relative;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
.mat-tab-body-content {
  overflow-x: hidden !important;
}

[dir='rtl'] {
  * {
    font-family: JF-Flat, Open Sans, Helvetica, Arial, sans-serif!important;
  }
}

.wishlist-icon {
  fill: #d4d4d4 !important;
  .light & {
    fill: #000000 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
