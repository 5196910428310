.card {
  --added-to-cart-button-text-color: #d4d4d4;
  --added-to-cart-button-bg-color: #3b4242;

  ::ng-deep.light & {
    --added-to-cart-button-text-color: #eb2027;
    --added-to-cart-button-bg-color: #eee;
  }

  border-radius: 10px;
  border: 0;
  margin: 0;
  min-width: 235px;
  max-width: 235px;

  &-img-top {
    border-radius: 10px 10px 0 0;
    min-height: 150px;
    max-height: 150px;
    object-position: center center;
    object-fit: cover;

    @supports (aspect-ratio: 1 / 0.6) {
      aspect-ratio: 1 / 0.6;
    }
  }

  &-body {
    padding: 15px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 100%;
    background-color: var(--main-background-color);
    border-radius: 0 0 10px 10px;
  }


  &-title {
    font-size: var(--course-title-font-size, 16px);
    font-weight: var(--course-title-font-weight, 500);
    line-height: 1.25;
    margin-bottom: 6px;
  }

  &-text {
    font-size: 14px;

    del {
      color: var(--card-old-price-color);
    }
  }

  &-call-to-action {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: var(--card-call-to-action-color);

    p {
      font-size: 12px;
    }

    ::ng-deep.btn-danger {
      display: flex;
      align-items: center;

      svg {
        width: 15px;
        height: 20px;

        [dir="ltr"] {
          margin-right: 4px !important;
        }

        [dir="rtl"] {
          margin-left: 4px !important;
        }

      }
    }

    .btn-icon {
      padding: 0;

      svg {
        fill: var(--card-call-to-action-fill);
      }
    }

    .added-to-cart {
      background-color: var(--added-to-cart-button-bg-color) !important;
      color: var(--added-to-cart-button-text-color) !important;
      display: flex;
      align-items: center;
    }
  }

  &-footer {
    margin-top: auto;
    padding: 0;
    border-radius: 0;
    background: transparent;
    border: 0;
    width: 100%;

    p {
      margin-bottom: 6px;
    }
  }

  .card-text-content {
    margin-bottom: 6px;
    display: flex;

    .card-text {
      .discount {
        color: var(--card-discount-color);
        margin: 0 5px;
      }
    }

    p {
      margin: 0;
    }
  }
}

.labels-wrapper{
  position: absolute;
  display: flex;
  .course-label{
    position: relative;
    &-private{
      background-color: #f0cd43;
      color: #463600;
    }
    
  }
}

  .course-label {
    position: absolute;
    border-radius: 5px;
    top: 0;
    margin: 10px;
    background-color: #252a2a;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.36;

    &-1 {
      color: #ff907e;
    }
    &-2 {
      margin: 5px !important;
      background-color: #afe0e7 !important;
      color: #000 !important;
    }
    &-3 {
      color: #85cddc;
    }
    &-private{
      background-color: #f0cd43;
      color: #463600;
    }
   
    
  }
  



[dir='ltr'] {
  .course-label {
    left: 0;
  }
}

[dir='rtl'] {
  .course-label {
    right: 0;
  }
}
