:root {
  --font-family-sans-serif: "Roboto", sans-serif;
}

:root {
  --metal-300: #3b4242;
  --metal-400: #252a2a;
  --metal-500: #1b1f1f;

  --white: #ffffff;
  --white-300: #f2f2f2;
  --white-400: #f3f1f1;
  --white-500: #d4d4d4;
  --white-600: #e8e8e8;
  --white-700: #a1a1a1;
  --white-800: #ffffff;

  --grey-300: #707070;
  --grey-400: #6c6c6c;
  --grey-500: #5d6666;
  --grey-800: #343434;
  --grey-900: #5e6c6c;

  --dark-500: #0c0e0e;
  --dark-700: #0a1726;
  --dark-800: #141717;
  --dark-900: #000000;

  --red-200: #ff907e;
  --red-500: #eb2027;
  --red-600:#ff2d17;


  --blue-200: #d5e5e8;
  --blue-300: #afe0e7;
  --blue-400: #85cddc;
  --blue-500: #0a8db1;
  --blue-700: #2f5a66;

  --blue-facebook: #4267b2;
  --red-google: #ea4335;


  --main-background-color: var(--dark-800);
  --card-background-color: var(--dark-800);
  --card-alt-background-color: var(--metal-400);
  --madal-background-color: #1a1a1a;
  --main-text-color: var(--white-700);
  --white-text-color: var(--white-800);


  .light {
    --main-background-color: var(--white-400);
    --card-background-color: var(--white-300);
    --card-alt-background-color: var(--white-500);
    --madal-background-color: var(--white-400);
    --main-text-color: var(--dark-800);
    --white-text-color: var(--dark-900);
  }
}

