:root {
  --form-control-text-color: #d4d4d4;
  --form-control-background: var(--metal-400);
  --form-control-focused-background: var(--metal-500);
  --modal-skip-button-color: var(--white-700);
  --modal-phone-code-color: #d4d4d4;
}

.light {
  --form-control-text-color: #3b4242;
  --form-control-background: var(--white-400);
  --form-control-focused-background: var(--white-300);
  --modal-phone-code-color: #6c6c6c;
}

input {
  padding: 10px !important;
  background-color: var(--form-control-background) !important;
}

.form-control {
  background-color: var(--form-control-background);
  border: 0;
  width: 100%;
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: var(--form-control-focused-background);
  }
}

.form-group {
  margin-bottom: 25px;
  .gender-radio-options {
    display: flex;
    &>.btn {
      flex: 1 1 auto
    }
  }
}

.mat-mdc-form-field {
  margin: 0 0 5px 0 !important;
  --mat-select-enabled-arrow-color: var(--form-control-text-color);
  --mat-select-focused-arrow-color: var(--form-control-text-color);

  svg {
    fill: #a1a1a1;

    &.light {
      fill: #252a2a;
    }
  }
  &-icon {
    &-prefix, &-suffix {
      padding: 0px !important;
    }
  }
  .prefix-icon {
    background-color: var(--form-control-background);
    display: flex;
    justify-content: center;
    align-items: center;

    [dir='ltr'] & {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    [dir='rtl'] & {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    height: 45px;
    width: 30px;

    svg {
      [dir='ltr'] & {
        margin-left: 7px;
      }

      [dir='rtl'] & {
        margin-right: 7px;
      }
    }
  }

  .suffix-icon {
    background-color: var(--form-control-background);
    display: flex;
    justify-content: center;
    align-items: center;

    [dir='ltr'] & {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    [dir='rtl'] & {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    height: 45px;
    width: 30px;

    svg {
      [dir='ltr'] & {
        margin-right: 7px;
      }

      [dir='rtl'] & {
        margin-left: 7px;
      }
    }
  }

  .phone-prefix {
    width: 85px;

    .phone-code {
      width: 45px;
      margin: 0 auto;
      border-right: 1px solid var(--modal-skip-button-color);
      border-left: unset;
      color: var(--modal-phone-code-color);
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix {
    padding: 2px 0 6px 0;
    margin: auto;

    svg {
      width: 20px;
      height: 20px;
      fill: #a1a1a1;

      .light & {
        fill: #6c6c6c;
      }
    }
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-suffix {
    padding: 2px 0 6px 0;
    margin: auto;

    svg {
      width: 20px;
      height: 20px;
      fill: #a1a1a1;

      .light & {
        fill: #6c6c6c;
      }
    }
  }

  .mdc-notched-outline__trailing, .mdc-notched-outline__leading {
    border-color: transparent!important;
  }

  .mat-mdc-input-element.form-control {
    height: 45px !important;

    &::placeholder {
      color: var(--form-control-text-color);
      opacity: 0.6;
    }

    color: var(--form-control-text-color);
  }

  .mat-mdc-select {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--form-control-background) !important;
    height: 28px;
    padding-top: 0;
    padding-bottom: 0;
    .mat-mdc-select-trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 45px;
      width: 100%;
      .mat-mdc-select-value {
        color: var(--form-control-text-color);
        opacity: 1;
        max-width: none;
        // padding: 8px 12px 7px 0px;
      }
      .mat-mdc-select-arrow-wrapper {
        transform: none !important;
        margin-right: 0;
        // margin-left: 12px;
        .mat-mdc-select-arrow {
          width: 12px;
          height: 12px;
          border: 0;
          background: url("/assets/images/arrow-down-dark.svg") no-repeat center;

          .light & {
            background: url("/assets/images/arrow-down-light.svg") no-repeat center;
          }
          svg {
            display: none;
          }
          background-size: contain;
          fill: #ffffff;
        }
      }
    }
  }
  .mat-mdc-select-placeholder {
    color: var(--form-control-text-color);
    opacity: 0.6;
    font-size: 14px;

    .light & {
      opacity: 1;
    }

  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      margin-bottom: 10px;
      padding: 0 !important;
    }
  }
  .mat-mdc-form-field-infix {
    padding: 0!important;
    border-top: none;
    min-height: 28px;

    .form-control {
      border-radius: 4px;
      padding: 20px 12px;
    }
  }

  &.with-prefix {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-prefix {
      top: 0.25em !important;
    }

    &.select-form-field {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-prefix {
        top: 0.27em !important;
      }
    }

    [dir='ltr'] & {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-infix {
        padding: 7px 2px 2px 0;

        .form-control {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-prefix {
        padding-left: 2px;
      }
    }

    [dir='rtl'] & {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-infix {
        padding: 7px 0 2px 2px;

        .form-control {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-prefix {
        padding-right: 2px;
      }
    }
  }


  &.with-suffix {
    [dir='ltr'] & {
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-infix {
        padding: 7px 0 2px 0;

        .form-control {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-suffix {
        padding-right: 2px;
      }
    }

    [dir='rtl'] & {
      .mat-mdc-form-field-infix {

        .form-control {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-suffix {
        padding-left: 2px;
      }
    }
  }

  &.no-rtl {
    [dir="rtl"] & {
      &.with-prefix {
        direction: ltr;
        .mat-mdc-form-field-infix {

          .form-control {
            border-bottom-right-radius: 4px !important;
            border-top-right-radius: 4px !important;
            text-align: left;
          }


        }

        .prefix-icon {
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-prefix {
          padding-right: 0 !important;
        }
      }
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-outline{
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline-end{
          border-right-style: solid !important;
          border-left-style: none !important;
          border-radius: 0 5px 5px 0 !important;
        }
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline-start{
          border-left-style: solid !important;
          border-right-style: none !important;
          border-radius: 5px 0 0 5px !important;
        }
      }
    }
  }
}

[dir="rtl"] {
  .mat-mdc-form-field {
    .phone-prefix {
      .phone-code {
        border-left: 1px solid var(--modal-skip-button-color) !important;
        border-right: unset !important;
      }
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--form-control-background) !important;
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: currentColor;
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-mdc-form-field-flex:hover .mdc-notched-outline {
  opacity: 1 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mdc-notched-outline {
  color: #ff907e !important;
  opacity: 1 !important;
  transition: opacity 100ms cubic-bezier(0.25, 0.8, 0.25, 1);
  outline: solid;
  border-radius: 4px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-subscript-wrapper {
  margin-top: 15px !important;
}

.mat-mdc-select-panel {
  background-color: var(--form-control-background) !important;
  .mdc-list-item__primary-text, .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: var(--form-control-text-color);
  }

  .mat-mdc-input-element {
    height: 45px;

    &::placeholder {
      color: var(--form-control-text-color);
      opacity: 0.6;
    }

    color: var(--form-control-text-color);
  }
}

.radio-as-buttons {
  position: relative;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    left: 0;
    z-index: -1;

    & + label {
      [dir="ltr"] & {
        border-radius: 0 0.25rem 0.25rem 0;
      }

      [dir="rtl"] & {
        border-radius: 0.25rem 0 0 0.25rem;
      }
      &.btn {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      svg {
        fill: var(--red-200)
      }
    }

    &:first-child {
      & + label {
        [dir="ltr"] & {
          border-radius: 0.25rem 0 0 0.25rem;
        }

        [dir="rtl"] & {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }

    &:checked + label, &:hover + label, & + label:hover {
      background-color: var(--red-200);
      color: #000;

      svg {
        fill: #000;
      }
    }
  }
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 5px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: var(--form-control-text-color);
  -webkit-box-shadow: 0 0 0 45px var(--form-control-background) inset !important;
  transition: none;
}

.mat-mdc-option {
  &[aria-disabled=true].contains-mat-select-search {
    height: 0;
  }
  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.mdc-form-field {
  .mdc-label {
    color: var(--form-control-text-color);
    margin: 0px;
  }
}
.cdk-overlay-pane {
  min-width: fit-content;
}
