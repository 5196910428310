.dark {
  background: #000000;
  color: var(--white-300);
}

.light {
  background: #ffffff;
  color: #000;
}

#launcher{
  @media (max-width: 991px) {
    bottom: 65px !important;
  }
}
