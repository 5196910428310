@media (min-width: 768px) {
  .carousel-no-arrows {
    margin: 0 50px;
  }
}

// this class is used as a workaround for a chromium bug starting from v121.0
// Bug Description: lazy loading images not loaded for images inside carousel in RTL direction
// Bug Link: https://issues.chromium.org/issues/41495751
body[dir='rtl'] {
  .carousel-lazyload-fix {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

